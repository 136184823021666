import { userLogin } from "../../../api/app";
import { getConfiguration } from "../../../utils/configurationUtil";

export async function VerifyEmailOTP(
  username,
  password,
  emailotp,
  verifyemailtoastMessage,
) {
  const data = {
    email: username,
    password: password,
    rememberMe: true,
    IsDryRun: false,
  };

  try {
    const verifyResponse = await fetch(getConfiguration("verifyemailapiurl"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: emailotp,
        email: username,
      }),
    });

    if (verifyResponse.status === 200) {
      verifyemailtoastMessage(verifyResponse);
      const userLoginResponse = await userLogin(data);
      if (userLoginResponse.isSuccess) {
        return userLoginResponse; // Return the userLogin response
      } else {
        // Handle unsuccessful user login
        throw new Error("Error in user login");
      }
    } else {
      verifyemailtoastMessage(verifyResponse);
      return verifyResponse; // Return the verifyResponse if status is not 200
    }
  } catch (error) {
    throw new Error("Failed to verify email or login"); // Throw an error to handle it externally
  }
}
