import { combineReducers } from "redux";

import rootAppBarReducer from "./rootAppBarReducer";
import rootAuthReducer from "./rootAuthReducer";
import rightDrawerReducer from "./rightDrawerReducer";

export default combineReducers({
  rootAppBar: rootAppBarReducer,
  rootAuth: rootAuthReducer,
  rightDrawer: rightDrawerReducer,
});
