const ROOT_APPBAR_DISABLE_DETAIL_VIEW_ACTION =
  "ROOT_APPBAR_DISABLE_DETAIL_VIEW_ACTION";
const ROOT_APPBAR_SET_LIST_TITLE = "ROOT_APPBAR_SET_LIST_TITLE";
const ROOT_APPBAR_SET_LIST_ACTIONS = "ROOT_APPBAR_SET_LIST_ACTIONS";
const ROOT_APPBAR_RESET_LIST = "ROOT_APPBAR_RESET_LIST";
const ROOT_APPBAR_SET_DETAILVIEW_ACTIONS = "ROOT_APPBAR_SET_DETAILVIEW_ACTIONS";
const ROOT_APPBAR_SET_DETAIL_VIEW_BACK_BUTTON_ACTION =
  "ROOT_APPBAR_SET_DETAIL_VIEW_BACK_BUTTON_ACTION";
const ROOT_APPBAR_SET_DETAILVIEW_TITLE = "ROOT_APPBAR_SET_DETAILVIEW_TITLE";
const ROOT_APPBAR_RESET_DETAILVIEW = "ROOT_APPBAR_RESET_DETAILVIEW";
const ROOT_APPBAR_SET_SEARCH_OPTION = "ROOT_APPBAR_SET_SEARCH_OPTION";
const ROOT_APPBAR_SET_SEARCH_TEXT = "ROOT_APPBAR_SET_SEARCH_TEXT";
const ROOT_APPBAR_SET_FILTER_APPLIED = "ROOT_APPBAR_SET_FILTER_APPLIED";
const ROOT_APPBAR_SET_MAX_DETAIL_VIEW_ACTION_COUNT =
  "ROOT_APPBAR_SET_MAX_DETAIL_VIEW_ACTION_COUNT";
const ROOT_APPBAR_RESET = "ROOT_APPBAR_RESET";

const RIGHT_DRAWER_SET_STATE = "RIGHT_DRAWER_SET_STATE";
const RIGHT_DRAWER_SET_COMPONENT = "RIGHT_DRAWER_SET_COMPONENT";
const RIGHT_DRAWER_SET_POSITION = "RIGHT_DRAWER_SET_POSITION";
const RIGHT_DRAWER_RESET = "RIGHT_DRAWER_RESET";
const RIGHT_DRAWER_SET_TITLE = "RIGHT_DRAWER_SET_TITLE";
const RIGHT_DRAWER_SET_CLEAR_STATE = "RIGHT_DRAWER_SET_CLEAR_STATE";
const RIGHT_DRAWER_SET_CLEAR_ACTION = "RIGHT_DRAWER_SET_CLEAR_ACTION";

const SET_RENDER_DETAIL_VIEW = "SET_RENDER_DETAIL_VIEW";
const SET_CURRENT_ACTIVE_PAGE = "SET_CURRENT_ACTIVE_PAGE";

export default {
  ROOT_APPBAR_DISABLE_DETAIL_VIEW_ACTION,
  ROOT_APPBAR_SET_LIST_TITLE,
  ROOT_APPBAR_SET_LIST_ACTIONS,
  ROOT_APPBAR_RESET_LIST,
  ROOT_APPBAR_SET_DETAILVIEW_TITLE,
  ROOT_APPBAR_SET_DETAILVIEW_ACTIONS,
  ROOT_APPBAR_SET_DETAIL_VIEW_BACK_BUTTON_ACTION,
  ROOT_APPBAR_RESET_DETAILVIEW,
  ROOT_APPBAR_SET_SEARCH_OPTION,
  ROOT_APPBAR_SET_SEARCH_TEXT,
  ROOT_APPBAR_SET_FILTER_APPLIED,
  ROOT_APPBAR_SET_MAX_DETAIL_VIEW_ACTION_COUNT,
  ROOT_APPBAR_RESET,

  RIGHT_DRAWER_SET_STATE,
  RIGHT_DRAWER_SET_COMPONENT,
  RIGHT_DRAWER_SET_POSITION,
  RIGHT_DRAWER_SET_TITLE,
  RIGHT_DRAWER_RESET,
  RIGHT_DRAWER_SET_CLEAR_STATE,
  RIGHT_DRAWER_SET_CLEAR_ACTION,

  SET_RENDER_DETAIL_VIEW,
  SET_CURRENT_ACTIVE_PAGE,
};
