import { connect } from "react-redux";

import { deleteNotificationTimeout } from "./constants/notificationConstants";

import { notificationActions } from "./common/actions/NotificatioActions";
import { changeOnlineStatus } from "./appActions";

import PWAPresentation from "./pwa-presentation";

const mapStateToProps = (state) => {
  return {
    onLine: state.app.onLine,
    windowSize: state.app.windowSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOnlineStatus: () => {
      dispatch(changeOnlineStatus());
    },

    // toast notification message
    pushNotification: (type, message, title, id, canRemove) => {
      dispatch(notificationActions.push(type, message, title, id, canRemove));
    },
    removeNotification: (id) => {
      dispatch(notificationActions.removeNotification(id));
      setTimeout(() => {
        dispatch(notificationActions.deleteNotification(id));
      }, deleteNotificationTimeout);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PWAPresentation);
