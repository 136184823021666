import ACTION_TYPES from "../types";

const initialState = {
  // List state
  listTitle: "",
  listActions: [],
  search: {},
  searchText: "",
  isFilterApplied: false,

  // Detail view state
  detailViewTitle: "",
  detailViewActions: [],
  detailViewBackButtonAction: {},
  disableDetailViewActions: false,
  maxDetailViewActionCount: {},
};

const rootAppBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ROOT_APPBAR_SET_LIST_TITLE:
      state = {
        ...state,
        listTitle: action.payload,
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_LIST_ACTIONS:
      state = {
        ...state,
        listActions: [...action.payload],
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_RESET_LIST:
      state = {
        ...state,
        listTitle: initialState.listTitle,
        listActions: [...initialState.listActions],
        search: { ...initialState.search },
        searchText: initialState.searchText,
        isFilterApplied: initialState.isFilterApplied,
      };
      break;

    case ACTION_TYPES.ROOT_APPBAR_SET_DETAILVIEW_TITLE:
      state = {
        ...state,
        detailViewTitle: action.payload,
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_DETAILVIEW_ACTIONS:
      state = {
        ...state,
        detailViewActions: [...action.payload],
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_RESET_DETAILVIEW:
      state = {
        ...state,
        detailViewTitle: initialState.detailViewTitle,
        detailViewActions: [...initialState.detailViewActions],
        detailViewBackButtonAction: {
          ...initialState.detailViewBackButtonAction,
        },
        disableDetailViewActions: initialState.disableDetailViewActions,
        maxDetailViewActionCount: { ...initialState.maxDetailViewActionCount },
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_SEARCH_OPTION:
      state = {
        ...state,
        search: { ...action.payload },
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_SEARCH_TEXT:
      state = {
        ...state,
        searchText: action.payload,
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_DETAIL_VIEW_BACK_BUTTON_ACTION:
      state = {
        ...state,
        detailViewBackButtonAction: { ...action.payload },
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_MAX_DETAIL_VIEW_ACTION_COUNT:
      state = {
        ...state,
        maxDetailViewActionCount: { ...action.payload },
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_DISABLE_DETAIL_VIEW_ACTION:
      state = {
        ...state,
        disableDetailViewActions: action.payload,
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_SET_FILTER_APPLIED:
      state = {
        ...state,
        isFilterApplied: action.payload,
      };
      break;
    case ACTION_TYPES.ROOT_APPBAR_RESET:
      state = { ...initialState };
      break;
  }
  return state;
};

export default rootAppBarReducer;
