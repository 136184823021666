const initialState = {
  title: "",
};

const unAuthAppBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UNAUTH_APPBAR_UPDATE_TITLE":
      state = {
        ...state,
        title: action.payload,
      };
      break;
  }
  return state;
};

export default unAuthAppBarReducer;
