const initialState = {
  pageIntroTitle: "",
  unAuthImg: "",
};

const unAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UNAUTH_UPDATE_IMAGE":
      state = {
        ...state,
        unAuthImg: action.payload,
      };
      break;
    case "UNAUTH_PAGE_INTRO_TITLE":
      state = {
        ...state,
        pageIntroTitle: action.payload,
      };
      break;
  }
  return state;
};

export default unAuthReducer;
