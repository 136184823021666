import ACTION_TYPES from "../types";

const initialState = {
  currentActivePage: "",
  setDetailView: false,
};

const rootAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_RENDER_DETAIL_VIEW:
      state = {
        ...state,
        setDetailView: action.payload,
      };
      break;
    case ACTION_TYPES.SET_CURRENT_ACTIVE_PAGE:
      state = {
        ...state,
        currentActivePage: action.payload,
      };
      break;
  }
  return state;
};

export default rootAuthReducer;
