import React, { useEffect, Suspense, lazy, useContext } from "react";

import AppBar from "./common/components/AppBar/AppBarContainer";
import Drawer from "./common/components/Drawer/DrawerContainer";
import MultiDrawer from "./common/components/MultiDrawer/MultiDrawerContainer";
import NavBar from "./common/components/Navbar/NavBarContainer";
import Sort from "./common/components/Sort/SortContainer";

import { notificaitonType } from "./constants/notificationConstants";
import { NO_INTERNET_CONNECTION } from "./constants/ErrorMessage";

import { retry } from "./utils/componentUtil";

import ComponentLoader from "./common/components/Loader/ComponentLoader";

import { LazyImageIntersectionObserverContext } from "./Context/LazyImage/LazyImageIntersectionObserverContext";
import { LazyImageIntersectionObserverProvider } from "./Context/LazyImage/LazyImageIntersectionObserverProvider";

const AppScreen = lazy(() => retry(() => import("./app-container")));

const PWAPresentation = (props) => {
  const observer = useContext(LazyImageIntersectionObserverContext);

  window.addEventListener("load", function() {
    props.changeOnlineStatus();
    window.addEventListener("online", props.changeOnlineStatus);
    window.addEventListener("offline", props.changeOnlineStatus);
  });

  useEffect(() => {
    if (!props.onLine)
      props.pushNotification(
        notificaitonType.default,
        NO_INTERNET_CONNECTION,
        undefined,
        "offline_message",
        false,
      );
    else props.removeNotification("offline_message");
  }, [props.onLine]);

  return (
    <LazyImageIntersectionObserverProvider value={observer}>
      <AppBar />
      <NavBar />
      <Sort />
      <Drawer />
      <MultiDrawer />
      <Suspense fallback={<ComponentLoader />}>
        <AppScreen />
      </Suspense>
    </LazyImageIntersectionObserverProvider>
  );
};

export default PWAPresentation;
