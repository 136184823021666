import ACTION_TYPES from "../types";

const initialState = {
  component: null,
  isOpen: false,
  position: "left",
  title: "",
  showClearButton: false,
  clearAction: null,
};

const rightDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RIGHT_DRAWER_SET_STATE:
      state = {
        ...state,
        isOpen: action.payload,
      };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_SET_COMPONENT:
      state = {
        ...state,
        component: action.payload,
      };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_RESET:
      state = { ...initialState };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_SET_POSITION:
      state = {
        ...state,
        position: action.payload,
      };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_SET_TITLE:
      state = {
        ...state,
        title: action.payload,
      };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_SET_CLEAR_STATE:
      state = {
        ...state,
        showClearButton: action.payload,
      };
      break;
    case ACTION_TYPES.RIGHT_DRAWER_SET_CLEAR_ACTION:
      state = {
        ...state,
        clearAction: action.payload,
      };
      break;
  }
  return state;
};

export default rightDrawerReducer;
